/* eslint-disable @nx/enforce-module-boundaries */
import { forwardRef, useEffect, useMemo, useState } from 'react';
import Carousel from 'react-multi-carousel';
import styles from './reelsBox.module.scss';
import { isMobile } from 'react-device-detect';
import { Card } from '@mybridge/ui/card';
import { Image, Stack } from '@chakra-ui/react';
import { UserDetailsPopover } from 'v4/components/user-details-popover';
import { Link } from '@mybridge/ui/link';
import { async } from '@firebase/util';
import { fetchReels } from 'v4/lib/api/reels';
import { useRouter } from 'next/router';
import { DashboardReelsModal } from '../reel-modal';
import { Reels } from '@mybridge/icons';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';

export const ReelsBox = forwardRef(({ ...props }, ref) => {
  const [reelsData, setReelsData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalItems = 6;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: currentSlide === totalItems - 1 ? 0 : 55,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: currentSlide === totalItems - 2 ? 0 : 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: currentSlide === totalItems - 1 ? 0 : 30,
    },
  };
  const handleBeforeChange = (nextSlide) => {
    setCurrentSlide(nextSlide);
  };

  useEffect(() => {
    fetchReelsDetails();
  }, []);

  const fetchReelsDetails = async () => {
    await fetchReels(props.isFirstReelSection ? 1 : props.postIndex + 1, 6)
      .then(async (reelResult) => {
        if (reelResult.results.length > 0) {
          setReelsData(reelResult?.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {reelsData.length > 0 && (
        <Card
          id={`reels_${props.postIndex}`}
          className={styles.reelsBox}
          // overflow="hidden"
          key={props.postIndex}
          variant="thinBorderedCard"
          mx={1}
          mt={0}
          // mb={6}
          borderRadius={"none"}
          borderBottom="1px solid #e0e0e094"
          borderTop="1px solid #e0e0e094"

        >
          <div className={`${styles.title} ${styles.reelsFlexHead}`}>
            <span className="lh-0">
              <Reels />
            </span>
            <h5>Reels</h5>
          </div>
          <div className="slider-container">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              dotListClass=""
              draggable
              focusOnSelect={false}
              itemClass={styles.reelsItems}
              keyBoardControl
              minimumTouchDrag={80}
              partialVisible
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={2}
              swipeable
              beforeChange={handleBeforeChange}
            >
              {reelsData.map((post, postIndex) => {
                const userName = post?.company?.name?.length
                  ? post?.company?.name
                  : post?.author?.first_name || post?.author?.last_name
                  ? `${post?.author?.first_name} ${post?.author?.last_name}`
                  : post?.author?.display_name;
                const userProfilePc = post?.company?.name?.length
                  ? post?.company?.image
                  : post?.author?.profile_pic ||
                    post?.author?.default_profile_picture;
                return (
                  <Link
                    maxH="600px"
                    overflow="hidden"
                    borderRadius="4px"
                    w="100%"
                    key={postIndex}
                    href={`/?reel=${post?.id}&page=${
                      props.isFirstReelSection ? 1 : props.postIndex + 1
                    }`}
                    onClick={() => {
                      localStorage?.removeItem('commentCLicked', true);
                    }}
                  >
                    <Card 
                    
          _hover={{ opacity: 0.5 }}
                    
                    className={styles.reelsCard}>
                      <Image
                        className={styles.reelsThumbnail}
                        src={post?.video_thumbnail}
                      />
                      <div className={styles.overlay}></div>
                      <div className={styles.author}>
                        <Image
                          src={userProfilePc}
                          height="26px"
                          width="26px"
                          style={{ borderRadius: '50%' }}
                          loading="lazy"
                          alt={'test'}
                        />

                        <div className={styles.authorName}>{userName}</div>
                      </div>
                      {/* <h3>{post}</h3> */}
                    </Card>
                  </Link>
                );
              })}
            </Carousel>
          </div>
        </Card>
      )}
    </>
  );
});
